//
// Shadows
//

// General styles
[class*="shadow"] {
  @if $enable-transitions {
    transition: $transition-base;
  }
}

// Size variations
.shadow-sm--hover:hover {
  box-shadow: $box-shadow-sm !important;
}

.shadow--hover:hover {
  box-shadow: $box-shadow !important;
}

.shadow-lg--hover:hover {
  box-shadow: $box-shadow-lg !important;
}

.shadow-none--hover:hover {
  box-shadow: none !important;
}
