/* You can add global styles to this file, and also import other style files */
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(74,74,74,.8);
  z-index:10;
  display: flex;
  align-items: center;
  justify-content: center;
}
