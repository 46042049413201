//
// Nav pills
//

// General styles

.nav-pills {
  .nav-item:not(:last-child) {
    padding-right: $nav-pills-space-x;
  }

  .nav-link {
    padding: $nav-pills-padding-y $nav-pills-padding-x;
    color: $nav-pills-link-color;
    font-weight: 500;
    font-size: $font-size-sm;
    box-shadow: $nav-pills-box-shadow;
    background-color: $nav-pills-bg;
    transition: $transition-base;

    &:hover {
      color: $nav-pills-link-hover-color;
    }
  }

  .nav-link.active,
  .show > .nav-link {
    color: $nav-pills-link-active-color;
    background-color: $nav-pills-link-active-bg;
  }

  @include media-breakpoint-down(xs) {
    .nav-item {
      margin-bottom: $spacer;
    }
  }
}

@include media-breakpoint-down(sm) {
  .nav-pills:not(.nav-pills-circle) {
    .nav-item {
      padding-right: 0;
    }
  }
}

// Rounded circle nav pills

.nav-pills-circle {
  .nav-link {
    text-align: center;
    height: 60px;
    width: 60px;
    padding: 0;
    line-height: 60px;
    border-radius: 50%;
  }

  .nav-link-icon {
    i,
    svg {
      font-size: 1rem;
    }
  }
}
